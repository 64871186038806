import { FC, useState } from 'react'

const FlectereContent = (
  <div className='mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8'>
    <div className='mx-auto max-w-2xl lg:text-justify flex flex-col gap-10'>
      <img src='customers/flectere_dagworks.png' />
      <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
        Flectere + DAGWorks Case Study
      </p>
      <p className='text-md sm:text-lg leading-8 text-gray-600'>
        <b>Industry</b>: Financial Services &amp; consulting
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
        Who is Flectere?
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        <p>
          <a className='hover:underline text-dwlightblue' href='http://www.flectere.net'>
            Flectere (formerly known as Fractal Dataminds (FDm))
          </a>{' '}
          helps enterprises, startups and SME&apos;s take better decisions over time by adopting a
          scientific approach and using AI/ML to increase operational leverage. They not only
          provide design & advisory services to their customers, but also offer implementation and
          deployment of strategic decision support systems. They have experience enabling over 100
          high stakes decisions for enterprises, startups and SMEs in seven different industries.
          These decision support systems have driven over $10M USD of capital allocation by their
          clients. To ensure solutions are relevant & cutting edge,{' '}
          <a className='hover:underline text-dwlightblue' href='http://www.flectere.net'>
            Flectere
          </a>{' '}
          builds first hand validation on the latest technologies so that their customers are able
          to identify “signal vs noise” in the constantly evolving technology landscape.
        </p>
        <p className='mt-2'>
          <a className='hover:underline text-dwlightblue' href='http://www.flectere.net'>
            Flectere
          </a>
          &apos;s distinctive approach involves <i>fractalizing</i> problem statements, a process
          requiring iterative moves from low- to high-fidelity definitions and then reverse
          engineering the process by aggregating the conclusions. Managing such complex patterns
          necessitates near-perfect data & computational lineage. Since their founding in May 2020,
          they have been on the lookout for libraries and technologies that would help them in this
          fractalization process while maintaining high-quality lineage.
        </p>
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        The Challenge: a decision support system for portfolio optimization needs fine grained
        lineage
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        <p>
          <a className='hover:underline text-dwlightblue' href='http://www.flectere.net'>
            Flectere
          </a>{' '}
          was engaged with{' '}
          <a className='hover:underline text-dwlightblue' href='https://www.wealthzen.com/'>
            Wealthzen
          </a>
          , a fintech startup committed to helping investors make better financial decisions in
          their investment portfolios. Their engagement required the development of a portfolio
          optimization decision support system. Building a portfolio optimization and backtesting
          system is a non-trivial exercise. Having experience in the domain, Flectere knew that the
          development, maintenance, and productionization aspects of the system can heavily
          influence the long term success of such a project, which can require substantial
          engineering effort.
        </p>
        <p className='mt-3'>
          While building out the initial system they encountered the following challenges:
          <ol className='pl-5 list-disc'>
            <li>
              producing fine grained visibility into the connection between inputs and optimization
              decisions
            </li>
            <li>
              difficulty in expressing different strategies quickly to enable fast iteration, while
              also connecting easily with lineage
            </li>
            <li>testing and viewing different portfolio strategies</li>
          </ol>
        </p>
        <p className='mt-3'>
          These challenges were beginning to be felt by the project in the form of extra engineering
          effort. No client likes cost estimates that are inaccurate, and no consultancy likes
          eating into their margins. Being unsatisfied by the status quo and wanting to provide the
          best value for their clients, Flectere were looking for solutions to help.
        </p>
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
        Solution: Hamilton
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        <a className='hover:underline text-dwlightblue' href='http://www.flectere.net'>
          Flectere
        </a>{' '}
        discovered Hamilton at the apply-conference, hosted by tecton.ai, in April 2021. Despite
        Hamilton being in its pre-open-source stage, Flectere sensed it could be a valuable asset.
        The turning point arrived when Hamilton was open sourced and DAGWorks was accepted at
        Y-Combinator, nudging Flectere from a <i>discovery</i> phase to an
        <i>evaluation</i> phase, which just happened to coincide with engagement with&nbsp;
        <a className='hover:underline text-dwlightblue' href='https://www.wealthzen.com/'>
          Wealthzen
        </a>
        .
        <p className='mt-3'>
          <a className='hover:underline text-dwlightblue' href='http://www.flectere.net'>
            Flectere
          </a>
          &apos;s hunch led them into a design partnership with DAGWorks, where they initiated a
          proof of concept (POC). The POC objective was to migrate a complicated subset of the data
          transformation pipeline they had implemented for their engagement with{' '}
          <a className='hover:underline text-dwlightblue' href='https://www.wealthzen.com/'>
            Wealthzen
          </a>
          , and do so using only 25% of the development effort originally involved in writing the
          (non-Hamilton) pipeline. To provide an insight into scope, the POC involved migrating all
          the code from configuration ingestion, to price data fetching, portfolio metric
          calculations and constraint definitions, right up to the point where all the data was
          ready to be submitted for optimization jobs for the strategy backtesting.
        </p>
        <p className='mt-3'>
          The POC was then evaluated along the following dimensions:
          <ol className='pl-5 list-disc'>
            <li>
              Increased ease of understanding - to visualize code and lineage for quicker iterations
              and accurate implementations.
            </li>
            <li>
              Increased ease of expression - to enhance productivity through faster code writing.
            </li>
            <li>
              Improvements in testability - to write transformations at the most atomic level
              possible for effective unit testing.
            </li>
          </ol>
        </p>
        <p className='mt-3'>
          Furthermore, for{' '}
          <a className='hover:underline text-dwlightblue' href='http://www.flectere.net'>
            Flectere
          </a>{' '}
          it was important to ensure collaboration with the core development team of Hamilton was
          effective and efficient, as the technology evolved.
        </p>
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        Outcome:
        <br />
        Hamilton is not a heavy lift
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        The project was successfully implemented using only 25% of the budget that it originally
        took to implement the initial project. In Flectere&apos;s experience using and validating
        the latest technologies, it is important that one is able to quickly adopt tools in a
        reasonable amount of time, as “migration & implementation costs” are a big driver of fear
        when adopting any new technology. By successfully completing the POC and feeling confident
        in their evaluation of the work (see next sections) Flectere gained confidence in being able
        to recommend Hamilton + Hamilton UI to their client.
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        Outcome:
        <br />
        Simpler expression of data transformations
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        As a result of rewriting the pipeline with Hamilton, Flectere was able to write
        understandable code at a finer grained level to replace monolithic functions by leveraging
        Hamilton&apos;s software engineering constructs. In one instance, a large monolithic
        function (55 lines), was replaced by fourteen simpler functions, none of which was more than
        ten lines of code. The net result was that it was much faster to reason about the logic
        required to implement than the original.
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        Outcome:
        <br />
        Enhanced Understanding and Visualization
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        The adoption of Hamilton and the Hamilton UI led to a dramatic improvement in
        Flectere&apos;s ability to understand and visualize data dependencies, both in testing and
        production contexts. This clearer comprehension of their code was another significant result
        of the partnership.
        <p className='mt-3'>
          The availability of DAG visualization combined with the powerful DAG comparison features,
          on the DAGWorks Platform, made it much more efficient to have pull requests reviews and to
          onboard new members into the code base. This was instrumental in executing the migration
          at 25% of the original budget.
        </p>
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        Outcome:
        <br />
        Testability improvements
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        The ability to understand exactly what code path was executed for different test cases was
        key in ensuring that developers were able to create test cases that were a realistic
        representation of the production environment. In the previous implementation, the cognitive
        burden to mentally reason about the code, meant that it was tiresome to reason about writing
        realistic test cases. In fact, Flectere actually felt like they had more bandwidth to write
        realistic test cases with the Hamilton implementation, which contributed to confidence that
        the new pipeline was more reliable that the old one, even though it took 75% less time to
        implement!
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        Outcome:
        <br />
        Exceptional Support and Collaboration
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        Flectere experienced a high level of support and collaboration with the DAGWorks team. The
        fun and productive experience of working with them became one of the standout outcomes of
        the partnership. Several features and bug fixes were addressed with quick turnaround and
        cadence. The Flectere team never felt blocked.
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        Outcome:
        <br />
        Additions to Hamilton Open Source
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        As a result of the partnership, the broader Hamilton project also benefited, with several
        new features &amp; improved documentation added to Hamilton. Having a clear use case and
        access to the DAGWorks team behind Hamilton, meant that the discussion of features were
        grounded in concrete needs, and candidate open source releases could be easily verified and
        iterated upon, before wider release. A concrete example of a feature that was added, was the{' '}
        <a
          className='hover:underline text-dwred'
          href='https://hamilton.dagworks.io/en/latest/reference/decorators/subdag/'
        >
          `subdag`
        </a>
        &nbsp; and{' '}
        <a
          className='hover:underline text-dwred'
          href='https://hamilton.dagworks.io/en/latest/reference/decorators/parameterize_subdag/'
        >
          `parameterize_subdag`
        </a>
        &nbsp; decorators. With the desire to more easily express parameterized operations, the two
        teams co-designed these decorators and validated their use before releasing it in an
        official Hamilton release. These additions proved central to Flectere&apos;s Wealthizen
        project, and allowed the team to more easily express concepts central to the project.
      </p>
      <div className='mt-10 flex sm:flex-row flex-col items-center gap-x-6 gap-y-2'>
        <p className='text-md sm:text-lg leading-8 text-gray-600 br'>
          Interested in learning more?
        </p>
        <a
          href='https://calendly.com/stefan-dagworks/15-minute-intro-call-with-stefan'
          className='text-md sm:text-lg font-semibold leading-6 text-gray-900 hover:text-dwlightblue'
          target='_blank'
          rel='noreferrer'
        >
          Talk to Us <span aria-hidden='true'>📞</span>
        </a>
        <a
          href='https://auth.app.dagworks.io/signup'
          className='rounded-md bg-dwred px-3.5 py-2.5 text-md sm:text-lg font-semibold text-white shadow-sm hover:bg-dwred/80 focus-visible:outline
          focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dwdarkblue'
        >
          Get started for free
        </a>
      </div>
    </div>
  </div>
)

const KoraMoneyContent = (
  <div className='mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8'>
    <div className='mx-auto max-w-xl lg:text-justify flex flex-col gap-10'>
      <img src='customers/kora_line_gradient.png' />
      <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
        Kora + DAGWorks Case Study
      </p>
      <p className='text-md sm:text-lg leading-8 text-gray-600'>
        <b>Industry</b>: Fintech
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
        Who is Kora?
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        <p>
          <a className='hover:underline text-dwlightblue' href='http://www.koramoney.com'>
            Kora
          </a>{' '}
          specializes in developing cutting-edge risk underwriting platforms that leverage machine
          learning and AI models to automate the underwriting process for auto and personal loans.
          By utilizing diverse data sources beyond traditional credit history, Kora’s financial
          products provide access to underserved groups, including recent immigrants, first-time
          buyers, individuals with thin credit files, and students.
        </p>
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        The Challenge: speed balanced with compliance and standardization
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        <p>
          As Kora continually enhances its risk underwriting models, they faced the challenge of
          ensuring clear data lineage to meet compliance requirements and standardization of data
          pipelines and MLOps processes. This standardization is crucial for Kora to foster seamless
          collaboration across teams.
        </p>
        <p>
          An additional challenge for Kora was that any solution must enable an efficient migration
          of their existing codebase without extensive code rewrites, given their constraints on
          development time and resources.
        </p>
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
        Solution: Hamilton &amp; Burr
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        <a className='hover:underline text-dwlightblue' href='http://www.koramoney.com'>
          Kora
        </a>{' '}
        addressed their challenges by utilizing both the{' '}
        <a
          className='hover:underline text-dwlightblue'
          href='http://github.com/dagworks-inc/hamilton'
        >
          Hamilton
        </a>{' '}
        and{' '}
        <a className='hover:underline text-dwlightblue' href='http://github.com/dagworks-inc/burr'>
          Burr
        </a>{' '}
        packages, organizing the existing code into structured units.
        <p className='mt-3'>
          The code was organized in the following manner:
          <ol className='pl-5 list-disc'>
            <li>
              <b>Node (Hamilton package)</b>: A node represents the basic building block of the new
              pipeline. It could be a transformation step, feature definition, or model prediction.
            </li>
            <li>
              <b>Pipeline (Hamilton package)</b>: A pipeline consists of multiple nodes and serves
              as a functional component within the underwriting workflow. For instance, a
              transaction-analysis pipeline processes raw bank transaction data, generating
              aggregated risk indicators and an overview of the financial profile.
            </li>
            <li>
              <b>Workflow (Burr package)</b>: A workflow orchestrates multiple steps, such as data
              loading and schema validation, and includes one or more pipelines. It functions as a
              higher-level data orchestration layer. For example, multiple Hamilton pipelines can be
              linked by specific business logic within a single workflow.
            </li>
          </ol>
        </p>
        <p className='mt-3'>
          The high compatibility of both Hamilton and Burr packages allowed for seamless integration
          with Kora&apos;s existing deployment environment, which includes Docker+EC2, MLFlow, and
          Sentry for tracking.
        </p>
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        Outcome: Ease of Use
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        Despite having no prior experience with either package, the Kora team successfully migrated
        a legacy data pipeline to the Hamilton structure and developed Burr workflows within two
        months.
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        Outcome: Compatibility
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        Both Hamilton and Burr packages are highly flexible and lightweight, which enabled seamless
        integration into Kora&apos;s existing infrastructure with little to no modification.
        Hamilton&apos;s &amp; Burr&apos;s built-in integrations with widely-used libraries such as
        MLflow and Pandera, were also utilized to streamline the project.
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        Outcome: Flow Lineage
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        Hamilton&apos;s DAG-based approach simplified data lineage, while Burr&apos;s clear state
        transformation improved workflow transparency. This new structure significantly enhanced
        Kora&apos;s productivity, streamlined their compliance checks, and improved collaboration
        efficiency, allowing the team to focus more on feature engineering and model iteration.
      </p>
      <p className='mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-left'>
        Future Plans:
      </p>
      <p className='mt-1 text-md sm:text-lg leading-8 text-gray-600'>
        With the successful completion of Kora&apos;s initial migration project, which demonstrated
        the robustness of the new Hamilton + Burr approach in a production environment, Kora plans
        to extend the migration to additional legacy pipelines. Additionally, they aim to develop a
        frontend monitoring system based on Hamilton&apos;s & Burr&apos;s UIs to track workflow
        performance in real time, further optimizing operational efficiency.
      </p>
      <div className='mt-10 flex sm:flex-row flex-col items-center gap-x-6 gap-y-2'>
        <p className='text-md sm:text-lg leading-8 text-gray-600 br'>
          Interested in learning more?
        </p>
        <a
          href='https://calendly.com/stefan-dagworks/15-minute-intro-call-with-stefan'
          className='text-md sm:text-lg font-semibold leading-6 text-gray-900 hover:text-dwlightblue'
          target='_blank'
          rel='noreferrer'
        >
          Talk to Us <span aria-hidden='true'>📞</span>
        </a>
      </div>
    </div>
  </div>
)

const CaseStudies: FC = () => {
  const [activeTab, setActiveTab] = useState('Kora Money')

  const caseStudies = [
    {
      name: 'Kora Money',
      industry: 'Fintech',
      content: KoraMoneyContent,
    },
    {
      name: 'Flectere',
      industry: 'Financial Services & Consulting',
      content: FlectereContent,
    },
  ]

  return (
    <div className='mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8'>
      <div className='flex justify-center mb-8'>
        {caseStudies.map((study) => (
          <button
            key={study.name}
            className={`px-4 py-2 mx-2 ${
              activeTab === study.name ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-800'
            } rounded`}
            onClick={() => setActiveTab(study.name)}
          >
            {study.name} - {study.industry}
          </button>
        ))}
      </div>
      <div className='mx-auto max-w-2xl lg:text-justify flex flex-col gap-10'>
        {caseStudies.find((study) => study.name === activeTab)?.content}
      </div>
    </div>
  )
}

export default CaseStudies
